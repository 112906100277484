import './App.scss'
import { useState } from 'react'
import Maze from './Maze'

function App() {
  const [cols, setCols] = useState(10)
  const [rows, setRows] = useState(10)
  const mazeData = `cols=${cols}&rows=${rows}&type=text&width=&height=&seed=`
  const [mazeDraw, setMazeDraw] = useState([])
  const [playerPos, setPlayerPos] = useState({})

  let mazeContainer = document.getElementById('maze')
  let intervalId

  const getMaze = async () => {
    const data = await fetch('/maze', {
      method: 'POST',
      body: mazeData,
    })
    const dataTxt = await data.text()
    setMazeDraw(
      dataTxt
        .replace(/(<([^>]+)>)|solution/gi, '')
        .split('\n')
        .slice(0)
    )
    return true
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault()
    const res = await getMaze()
    if (res) {
      intervalId = setInterval(() => {
        playerInit()
      }, 500)
    } else return
  }

  const placePlayer = () => {
    console.log('placePlayerLog', playerPos)
    clearInterval(intervalId)
    setPlayerPos({ i: 2, j: 0, win: false })
    mazeContainer.children[2].children[0].classList.add('hero')
    
  }

  const playerInit = () => {
    if (mazeContainer === null) {
      mazeContainer = document.getElementById('maze')
      console.log("c'est null")
      return
    } else {
      let init = mazeContainer.getElementsByClassName('hero')
      let iniArr = Array.from(init)
      if (iniArr.length === 1) {
        iniArr[0].classList.remove('hero')
        placePlayer()
      } else if (iniArr.length > 1) {
        iniArr.forEach((element) => element.classList.remove('hero'))
        placePlayer()
      } else {
        placePlayer()
      }
    }
  }

  const movePlayer = (newI, newJ, scale) => {
    let prevPlayerPos =
      mazeContainer.children[playerPos.i].children[playerPos.j]
    let nextPlayerPos = mazeContainer.children[newI].children[newJ]

    if (checkCollision(newI, newJ) && !checkWin(newI, newJ)) {
      prevPlayerPos.classList.remove('hero')

      // Check orientation of player
      if (scale === 1) {
        nextPlayerPos.classList.add('face-left')
      } else if (scale === -1) {
        nextPlayerPos.classList.add('face-right')
      } else if (prevPlayerPos.classList.contains('face-right')) {
        nextPlayerPos.classList.add('face-right')
      } else nextPlayerPos.classList.add('face-left')

      // Remove precedently orientation
      prevPlayerPos.classList.remove('face-right')
      prevPlayerPos.classList.remove('face-left')

      // Add new player position
      nextPlayerPos.classList.add('hero')
      setPlayerPos({ ...playerPos, i: newI, j: newJ })
    }
  }

  const checkCollision = (newI, newJ) => {
    let nextPlayerPos = mazeContainer.children[newI].children[newJ]
    if (
      nextPlayerPos === undefined ||
      nextPlayerPos.classList.contains('wall')
    ) {
      return false
    } else return true
  }

  const checkWin = (newI, newJ) => {
    if (
      newI === mazeDraw.length - 5 &&
      newJ === mazeDraw[mazeDraw.length - 5].length - 1
    ) {
      mazeContainer.children[playerPos.i].children[
        playerPos.j
      ].classList.remove('hero')
      mazeContainer.children[newI].children[newJ].classList.add('hero')
      let winDiv = document.createElement('div')
      winDiv.setAttribute('id', 'win')
      mazeContainer.append(winDiv)
      setPlayerPos({ ...playerPos, win: true })
      return true
    } else return false
  }

  onkeydown = (e) => {
    if (playerPos.win) return
    switch (e.code) {
      case 'ArrowRight':
        movePlayer(playerPos.i, playerPos.j + 1, -1)
        break
      case 'ArrowLeft':
        movePlayer(playerPos.i, playerPos.j - 1, 1)
        break
      case 'ArrowUp':
        movePlayer(playerPos.i - 1, playerPos.j)
        break
      case 'ArrowDown':
        movePlayer(playerPos.i + 1, playerPos.j)
        break
      default:
        break
    }
  }

  const reload = async () => {
    const reloaded = await getMaze()
    if (reloaded) {
      document.getElementById('win')?.remove()
      playerInit()
    }
  }

  

  return (
    <div className="App">
      <h1 className="mb-4 py-10 text-4xl text-center font-bold underline">
        Petit jeu - Labyrinthe
      </h1>
      <div className="text-center mb-10">
        <p>Déplacez vous avec les flèches du clavier</p>
      </div>

      <div className="mazeWrapper flex justify-center items-center text-center">
        <div className=" h-full flex flex-col justify-center items-center">
          <div className="inputsButtonWrapper flex flex-col gap-6 my-6 text-center border border-green-400 rounded-xl shadow-lg">
            <div className="inputsWrapper flex flex-col items-center gap-4 border-b-2 p-4 border-dotted">
              <form
                className="inputs flex flex-col gap-6 justify-center items-center text-center"
                onSubmit={handleSubmit}
              >
                <label
                  htmlFor="cols"
                  className="relative block rounded-md border border-gray-200 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
                >
                  <input
                    type="number"
                    id="cols"
                    className="peer border-none p-2 bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0"
                    onChange={(e) => setCols(e.target.value)}
                    value={cols}
                    max={15}
                  />

                  <span className="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs">
                    Nombre de Colonne
                  </span>
                </label>
                <label
                  htmlFor="rows"
                  className="relative block rounded-md border border-gray-200 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
                >
                  <input
                    type="number"
                    id="rows"
                    className="peer border-none p-2 bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0"
                    onChange={(e) => setRows(e.target.value)}
                    value={rows}
                    max={15}
                  />

                  <span className="pointer-events-none absolute start-2.5 top-0 -translate-y-1/2 bg-white p-0.5 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-0 peer-focus:text-xs">
                    Nombre de Ligne
                  </span>
                </label>
                {playerPos.win ? (
                  <button
                    className="col-span-1 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-6 w-40 my-6 border border-blue-500 hover:border-transparent rounded"
                    onClick={reload}
                  >
                    Recommencer
                  </button>
                ) : (
                  <button
                    className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-6 w-40 my-6 border border-blue-500 hover:border-transparent rounded"
                    type="submit"
                  >
                    Générer un labyrinthe
                  </button>
                )}
              </form>
            </div>
          </div>
          {!playerPos.win && (
            <div className=" flex h-full justify-center items-center my-6">
              <button
                className="h-12 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-6 mb-4 w-40 border border-blue-500 hover:border-transparent rounded"
                onClick={playerInit}
              >
                Recommencer
              </button>
            </div>
          )}
        </div>

        <Maze mazeDraw={mazeDraw} />
      </div>
    </div>
  )
}

export default App
