import React from 'react'

function Maze({mazeDraw}) {

  return (
    <div id="maze" className="w-max mx-6">
          {mazeDraw &&
            mazeDraw.map((line, index) => (
              <div className="line" key={index}>
                {line
                  .split('')
                  .map((cols, colsIndex) =>
                    cols === '+' || cols === '-' || cols === '|' ? (
                      <div className="wall" key={colsIndex}></div>
                    ) : (
                      <div className="" key={colsIndex} />
                    )
                  )}
              </div>
              ))}
              </div>
  )
}

export default Maze